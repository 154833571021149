import React from 'react';
import { Button } from '@blueprintjs/core';

class Header extends React.Component {
    email() {
        window.open('mailto:cristina_mau@outlook.com');
    }

    render() {
        return (
            <div className='header'>
                <div className='logo'>
                    <Button minimal large>CM.</Button>
                </div>

                <div className='nav'>
                    <Button className="projects" minimal large>Projects</Button>
                    <Button onClick={() => this.email()} minimal large>Contact</Button>
                </div>
            </div>
        )
    }
}

export default Header
import React from 'react';
import { Button } from '@blueprintjs/core';

class Studies extends React.Component {

    redirectToGoogleSlide(link) {
        window.location.href = link;
    }

    render() {
        return (
            <div className='studies'>
                <div className='case-container'>
                    <div className='screenshot-section'>
                        <img className='phone' alt='r1' src='/assets/case_studies/R1.png'></img>
                        <img className='phone' alt='r2' src='/assets/case_studies/R2.png'></img>
                        <img className='phone' alt='r3' src='/assets/case_studies/R3.png'></img>
                    </div>

                    <div className='case-info-section'>
                        <div className='title'>Reveal</div>

                        <div>
                            <div className='content'>
                                Assisting pet parents in identifying the ingredients best suited for their pets.
                            </div>
                            <div className='wip'>(Work in Progress)</div>
                        </div>

                        <Button onClick={() => this.redirectToGoogleSlide("https://docs.google.com/presentation/d/1BQwXDw4BeFjIiZdj25ROzjAemtI_wUF-XNwqaNzOknA/edit?usp=sharing")}>View Google Slides</Button>
                    </div>
                </div>

                <div className='case-container'>
                    <div className='screenshot-section'>
                        <img className='phone' alt='c1' src='/assets/case_studies/C1.png'></img>
                        <img className='phone' alt='c2' src='/assets/case_studies/C2.png'></img>
                        <img className='phone' alt='c3' src='/assets/case_studies/C3.png'></img>
                    </div>

                    <div className='case-info-section'>
                        <div className='title'>Recur</div>

                        <div>
                            <div className='content'>
                                Helping people manage their subscriptions.
                            </div>
                            <div className='wip'>(Work in Progress)</div>
                        </div>

                        <Button onClick={() => this.redirectToGoogleSlide("https://docs.google.com/presentation/d/1NsZ5uhX4-UuFAL3Lg5wPScEZtRkanSAPyqNVRxBc-rk/edit?usp=sharing")}>View Google Slides</Button>
                    </div>
                </div>

                <div className='case-container'>
                    <div className='screenshot-section'>
                        <img className='desktop' alt='recalls' src='/assets/case_studies/recalls.png'></img>
                    </div>

                    <div className='case-info-section'>
                        <div className='title'>Recalls</div>

                        <div>
                            <div className='content'>
                                Redesigning a responsive government website.
                            </div>
                            <div className='wip'>(Work in Progress)</div>
                        </div>

                        <Button onClick={() => this.redirectToGoogleSlide("https://docs.google.com/presentation/d/1j469iOOxQu7mBFrXdk_g9QClJXGm7cFGbvAOIWluv1I/edit?usp=sharing")}>View Google Slides</Button>
                    </div>
                </div>

                <div className='case-container'>
                    <div className='screenshot-section'>
                        <img className='desktop' alt='lsja' src='/assets/case_studies/lsja.png'></img>
                    </div>

                    <div className='case-info-section'>
                        <div className='title'>LSJA</div>

                        <div>
                            <div className='content'>
                                Redesigning a responsive nonprofit website.
                            </div>
                            <div className='wip'>(Work in Progress)</div>
                        </div>

                        <Button onClick={() => this.redirectToGoogleSlide("https://docs.google.com/presentation/d/1aAi_lwp2KGEJ53-EuPPCAouEVjv-_HWBuK8NafMqT64/edit?usp=sharing")}>View Google Slides</Button>
                    </div>
                </div>

            </div>
        )
    }
}

export default Studies
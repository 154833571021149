import React from 'react';
import { Button } from '@blueprintjs/core';

class Info extends React.Component {
    github() {
        window.location.href = "https://github.com/cristinamau";
    }

    linkedin() {
        window.location.href = "https://linkedin.com/in/cristina-m-876538146";
    }

    email() {
        window.open('mailto:cristina_mau@outlook.com');
    }

    render() {
        return (
            <div className='info'>
                <div className='text-section'>
                    <div className='name'>Hi! I'm Cristina Mauricio</div>
                    <div className='designer'>UX Designer</div>
                    <div className='description'>I am passionate about helping people solve their problems by creating simple, human-centric solutions.</div>
                </div>

                <div className='picture-section'>
                    <div>
                        <img alt='profile' className='picture' src='/assets/profilepicture.png'/>
                    </div>
                    <div>
                        <Button className="icon-button" onClick={() => this.linkedin()} minimal>
                            <img alt="linkedin" className="header-icon" src="/assets/icons/linkedin.png"/>
                        </Button>
                        <Button className="icon-button" onClick={() => this.github()} minimal>
                            <img alt="github" className="header-icon" src="/assets/icons/github.png"/>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Info